import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import {SharedThingsModule} from '../sharedThings.module';
import {RouterModule} from '@angular/router';
import {TableModule} from 'primeng/table';
import {LogComponent} from './log/log.component';
import { AddAdvertiserComponent } from '../_campaignMVC/advertisers/addAdvertiser/addAdvertiser.component';
import {SwitchComponent} from './switch/switch.component';
import {KpiComponent} from './kpi/kpi.component';
import {MapRadiusComponent} from './mapRadius/mapRadius.component';
import {AutofocusDirective} from '../__helpers/AutoFocusDirective';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {GroupSidebarModule} from '../_cerberusMVC/groupSidebar.module';
import {ProgressIntPipe} from '../__helpers/pipes/progressInt.pipe';
import {IsSearchedMediaPipe} from '../__helpers/pipes/isSearchedMedia.pipe';
import {TargetingPipe} from '../__helpers/pipes/targeting.pipe';
import { AreYouSureComponent } from './areYouSure/areYouSure.component';
import {IsSearchedPipe} from '../__helpers/pipes/isSearched.pipe';
import { GetElsBySpecifiedValuePipe } from '../__helpers/pipes/getElsBySpecifiedValue.pipe';
import { ProgressKpiPipe } from '../__helpers/pipes/progressKpi.pipe';
import {ConcatCommaPipe} from '../__helpers/pipes/concatComma.pipe';
import { ConcatNewlinePipe } from '../__helpers/pipes/concatNewline.pipe';
import {ConcatCreativesPipe} from '../__helpers/pipes/concatCreatives.pipe';
import { GetElBySingleIdPipe } from '../__helpers/pipes/getElBySingleId.pipe';
import { GetElemsInArrayPipe } from '../__helpers/pipes/getElemsInArray.pipe';
import { MapRadiusMultiComponent } from './mapRadiusMulti/mapRadiusMulti.component';
import {OperationPipe} from '../__helpers/pipes/operation.pipe';
import {GrafanaUrlPipe} from '../__helpers/pipes/grafanaUrl.pipe';
import {DealsService} from '../_dealsMVC/deals.service';
import { MessageBoxComponent } from './messageBox/messageBox.component';
import { EntityChooseComponent } from './entityChoose/entityChoose.component';
import { MainTagReminderComponent } from './mainTagReminder/mainTagReminder.component';
import {SegmentCodeModalComponent} from './segmentCodeModal/segmentCodeModal.component';
import {MetricSymbolPipe} from '../_cerberusMVC/cerberusBuyer/__helpers/pipe/metricSymbol.pipe';
import {StringToLabelDatePipe} from '../__helpers/dates/stringToLabelDate.pipe';
import {ReplaceLineBreaks} from '../__helpers/pipes/replaceLineBreaks.pipe';
import {AdsTxtChipsComponent} from './adsTxtChips/adsTxtChips.component';
import { UploadAgreementComponent } from './upload-agreement/upload-agreement.component';
import {ApprovalService} from '../_approvalMVC/approval/approval.service';
import {AreYouSureFlatComponent} from './areYouSureFlat/areYouSureFlat.component';
import {TreeSelectionComponent} from "./treeSelection/treeSelection.component";
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import {ChipsModule} from 'primeng/chips';
import {ChartModule} from 'primeng/chart';
import {TreeModule} from 'primeng/tree';
import {SliderModule} from 'primeng/slider';
import {GetEntityByTypePipe} from '../__helpers/pipes/getEntityByType.pipe';
import {CodeHighlighterComponent} from './CodeHighlighter/CodeHighlighterComponent';
import {ProgressIntSimplePipe} from '../__helpers/pipes/progressIntSimple.pipe';


@NgModule({
  imports: [
    SharedThingsModule,
    CommonModule,
    RouterModule,
    MessagesModule,
    MessageModule,
    GroupSidebarModule,
    ColorPickerModule,
    ChartModule,
    CalendarModule,
    SelectButtonModule,
    ChipsModule,
    TreeModule,
    TableModule,
    SliderModule,
  ],
  declarations: [
    AutofocusDirective,
    CodeHighlighterComponent,
    AreYouSureComponent,
    AreYouSureFlatComponent,
    ConcatCommaPipe,
    ConcatCreativesPipe,
    GetElBySingleIdPipe,
    GetElemsInArrayPipe,
    GetEntityByTypePipe,
    ProgressIntPipe,
    ProgressIntSimplePipe,
    ProgressKpiPipe,
    MetricSymbolPipe,
    GetElsBySpecifiedValuePipe,
    IsSearchedMediaPipe,
    IsSearchedPipe,
    TargetingPipe,
    ConcatNewlinePipe,
    GrafanaUrlPipe,
    OperationPipe,
    StringToLabelDatePipe,
    LogComponent,
    EntityChooseComponent,
    AddAdvertiserComponent,
    MainTagReminderComponent,
    MapRadiusComponent,
    MapRadiusMultiComponent,
    MessageBoxComponent,
    KpiComponent,
    SwitchComponent,
    SegmentCodeModalComponent,
    AdsTxtChipsComponent,
    ReplaceLineBreaks,
    UploadAgreementComponent,
    TreeSelectionComponent
  ],
  providers: [
    ApprovalService,
    MetricSymbolPipe,
    DealsService // For targeting
  ],
  exports: [
    ConcatCommaPipe,
    GetElBySingleIdPipe,
    GetElemsInArrayPipe,
    ProgressIntPipe,
    ProgressIntSimplePipe,
    ProgressKpiPipe,
    MetricSymbolPipe,
    ConcatCreativesPipe,
    GetElsBySpecifiedValuePipe,
    IsSearchedMediaPipe,
    IsSearchedPipe,
    GrafanaUrlPipe,
    TargetingPipe,
    ConcatNewlinePipe,
    OperationPipe,
    StringToLabelDatePipe,
    CommonModule,
    LogComponent,
    EntityChooseComponent,
    AddAdvertiserComponent,
    AreYouSureComponent,
    AreYouSureFlatComponent,
    MainTagReminderComponent,
    MapRadiusComponent,
    MapRadiusMultiComponent,
    MessageBoxComponent,
    KpiComponent,
    SwitchComponent,
    MessagesModule,
    MessageModule,
    GroupSidebarModule,
    SegmentCodeModalComponent,
    CodeHighlighterComponent,
    AdsTxtChipsComponent,
    ColorPickerModule,
    ChartModule,
    CalendarModule,
    SelectButtonModule,
    ChipsModule,
    TreeModule,
    TableModule,
    ReplaceLineBreaks,
    UploadAgreementComponent,
    TreeSelectionComponent,
    GetEntityByTypePipe,
  ]
})
export class SharedPrivateThingsModule {
}
