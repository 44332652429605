import {Constants} from './Constants';
import {SelectItem, TreeNode} from 'primeng/api';
import {Flight} from './Flight';
import {Preno} from './Preno';
import {UserData} from './UserData';
import {FormatInfo} from '../../_reportsMVC/_helper/FormatInfo';
import {StyleFormat} from './FormatHelper';
import {CurrencyHelper} from './CurrencyHelper';
import {EventsIds} from './EventsIds';
import NumberFormatOptions = Intl.NumberFormatOptions;

export class UtilsFun {

  public static cloneField(field: any): any {
    return JSON.parse(JSON.stringify(field));
  }

  // da usare se c'è una chiamata circolare all'interno dell'oggetto json
  public static deepClone(obj, hash = new WeakMap()) {
    // Do not try to clone primitives or functions
    if (Object(obj) !== obj || obj instanceof Function) { return obj }
    if (hash.has(obj)) { return hash.get(obj); } // Cyclic reference
    try { // Try to run constructor (without arguments, as we don't know them)
      var result = new obj.constructor();
    } catch (e) { // Constructor failed, create object without running the constructor
      result = Object.create(Object.getPrototypeOf(obj));
    }
    // Optional: support for some standard constructors (extend as desired)
    if (obj instanceof Map) {
      Array.from(obj, ([key, val]) => result.set(UtilsFun.deepClone(key, hash),
        UtilsFun.deepClone(val, hash)));
    } else if (obj instanceof Set) {
      Array.from(obj, (key) => result.add(UtilsFun.deepClone(key, hash)));
    }
    // Register in hash
    hash.set(obj, result);
    // Clone and assign enumerable own properties recursively
    return Object.assign(result, ...Object.keys(obj).map (
      key => ({ [key]: UtilsFun.deepClone(obj[key], hash) }) ));
  }

  // get the hash of a string
  public static getHashCode (str) {
    let hash = 0;
    if (!hash) {
      return 'general';
    }
    if (str.length === 0) { return hash; }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  // get the hash of a string
  public static getReportHash (str): number {
    let hash = 0;
    if (str.length === 0) { return hash; }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  public static getBetween(haystack: string, firstNeedle: string, secondNeedle: string): string {
    try {
      return haystack.substring(haystack.indexOf(firstNeedle) + firstNeedle.length, haystack.indexOf(secondNeedle));
    } catch (e) {
      return null;
    }
  }

  // Schermata di Caricamento in background, grandezza di logo
  public static getBackgroundLoadingImg() {
    if (UtilsFun.matchDestinationCarat()) {
      return {'background': 'url(' + '../../assets/dentsuLoader.png' + ')' + ' no-repeat center'};
    }
    return {'background': 'url(' + '../../assets/../../assets/ot2.png' + ')' + ' no-repeat center'};
  }

  public static matchDestinationCarat(): boolean {
    return location.host.indexOf('carat') === 0;
  }

  public static matchDestinationTest(): boolean {
    return location.host.indexOf('platform-test') === 0;
  }

  public static matchDestinationDev(): boolean {
    return location.host.indexOf('platform-dev') === 0 || location.host.indexOf('assets-dev') === 0;
  }

  public static matchDestinationSandbox(): boolean {
    return location.host.indexOf('platform-sandbox') === 0;
  }

  public static matchDebug(): boolean {
    return location.host.indexOf('localhost') === 0;
  }

  public static getReportLink(type: number, isAnalitics?: boolean): string {
    switch (type) {
      case Constants.REPORT_CAMPAIGN_ID: {
        if (isAnalitics) {
          return '/' + Constants.LINK_REPORT_PART + '/' + Constants.LINK_REPORT_HB;
        }
        return '/' + Constants.LINK_REPORT_PART + '/' + Constants.LINK_REPORT_CAMPAIGN;
      }
      case Constants.REPORT_REVENUE_ID: {
        return '/' + Constants.LINK_REPORT_PART + '/' + Constants.LINK_REPORT_REVENUE;
      }
      case Constants.REPORT_SEGMENT_ID: {
        return '/' + Constants.LINK_REPORT_PART + '/' + Constants.LINK_REPORT_SEGMENT;
      }
      case Constants.REPORT_PAGE_ID: {
        return '/' + Constants.LINK_REPORT_PART + '/' + Constants.LINK_REPORT_PAGE;
      }
      case Constants.REPORT_AUDIENCE_EXTENSION_ID: {
        return '/' + Constants.LINK_REPORT_PART + '/' + Constants.LINK_REPORT_AUDIENCE_EXTENSION;
      }
    }
    return '/' + Constants.LINK_REPORT_PART + '/' + Constants.LINK_REPORT_CAMPAIGN;
  }

  // Dimensioni della preview dei media definite dalla loro
  public static getModalDimensionPreview(id: number): {width: number, height: number} {

    switch (id) {
      case 2: {
        return ({width: 400, height: 300});
      }
      case 5: {
        return ({width: 800, height: 200});
      }
      case 7: {
        return ({width: 350, height: 650});
      }
      case 8: {
        return ({width: 1050, height: 300});
      }
      case 9: {
        return ({width: 400, height: 200});
      }
      case 14: {
        return ({width: 350, height: 650});
      }
      case 15: {
        return ({width: 350, height: 300});
      }
      case 16: {
        return ({width: 600, height: 200});
      }
      case 17: {
        return ({width: 400, height: 350});
      }
      case 18: {
        return ({width: 350, height: 650});
      }
      case 19: {
        return ({width: 1050, height: 200});
      }
      default : {
        return ({width: 500, height: 500});
      }
    }
  }

  /**
   * Funzione di sort delle tabelle
   * @param event
   * @param a
   * @param b
   * @param moreThen50 : boolean --- true se voglio torni il doppio di order se la differenza numerica è > del doppio
   * @returns {number}
   */
  public static sortFun(event, a, b, moreThen50?: boolean) {
    if (!a && !b) {
      return 0;
    }

    // allow negative numbers
    if ( Constants.numbRegex.test(a) && Constants.numbRegex.test(b) ) {       // Number Parse
      const aInt: number = Number(a);
      const bInt: number = Number(b);
      return UtilsFun.myCompare(aInt, bInt, event.order, moreThen50);
    }

    if (!a) {
      return -event.order;
    }
    if (!b) {
      return event.order;
    }
    if ( (Constants.currencyRegexSpace.test(a) && Constants.currencyRegexSpace.test(b)) || (Constants.currencyRegex.test(a) && (Constants.currencyRegex.test(b)))) {
      const aInt: number = Number(a.replace('.', '').replace(',', '').replace('$', '').replace('€', '').replace('£', '').replace(' ', ''));
      const bInt: number = Number(b.replace('.', '').replace(',', '').replace('$', '').replace('€', '').replace('£', '').replace(' ', ''));
      return UtilsFun.myCompare(aInt, bInt, event.order, moreThen50);
    } else if (Constants.percentageRegex.test(a) && Constants.percentageRegex.test(b) ) {  // Percentage Parse
      const resA = Number(a.slice(0, -1).replace(',', '.'));
      const resB = Number(b.slice(0, -1).replace(',', '.'));
      return UtilsFun.myCompare(resA, resB, event.order, moreThen50);
    } else if ((Constants.dateRegex.test(a) && Constants.dateRegex.test(b)) || (Constants.shortdateRegex.test(a) && Constants.shortdateRegex.test(b)) ) {  // Date Parse
      const resA = a.split('/').reverse().join('');
      const resB = b.split('/').reverse().join('');
      return UtilsFun.myCompare(resA, resB, event.order, moreThen50);
    } else if (Constants.partialdateRegex.test(a) && Constants.partialdateRegex.test(a)) {  // Date and hour Parse
      const resA = a.split(' ')[0].split('/').reverse().join('');
      const resB = b.split(' ')[0].split('/').reverse().join('');
      return UtilsFun.myCompare(resA, resB, event.order, moreThen50);
    } else if (Constants.hourRegex.test(a) && Constants.hourRegex.test(b)) {  // Hour Parse
      const resA = parseInt(a.split(':', 1)[0], 10);
      const resB = parseInt(b.split(':', 1)[0], 10);
      return UtilsFun.myCompare(resA, resB, event.order, moreThen50);
    } else if (a.label !== undefined && b.label !== undefined) {
      return UtilsFun.myCompareString(a.label, b.label, event.order);
    } else if (a.value !== undefined && b.value !== undefined) {
      return UtilsFun.myCompareString(a.label, b.label, event.order);
    }
    return UtilsFun.myCompareString(a, b, event.order);
  }

  public static isObjectEmpty(obj: any) {
    return !obj || Object.keys(obj).length === 0;
  }

  public static myCompareString(a: string, b: string, order): number {
    if (a !== undefined && b !== undefined) {
      if (a.toString().toUpperCase() < b.toString().toUpperCase()) {
        return -order;
      } else if (a.toString().toUpperCase() > b.toString().toUpperCase()) {
        return order;
      }
    }
    return 0;
  }

  /**
   * Funzione di comparazione di due valori
   * @param a valore 1
   * @param b valore 2
   * @param order -1 se decrescente; 1 altrimenti
   * @param moreThen50
   * @returns {number} -1 se a < b; 1 se a > b; 0 se a = b
   */
  public static myCompare(a: number, b: number, order, moreThen50?: boolean): number {
    if (moreThen50) {
      if ( a < b ) {
        if (b / a > 2) {
          return -order * 2
        }
        return -order;
      } else if ( a > b ) {
        if (a / b > 2) {
          return order * 2
        }
        return order;
      }
      return 0;
    }
    if ( a < b ) {
      return -order;
    } else if ( a > b ) {
      return order;
    }
    return 0;
  }

  public static normalize(el, sym?: string): string {
    const res = el + '';
    if (res === '-1' || res === '-1.00' || res === '-1,00') { return '-'}
    return el + (sym ? sym : '');
  }

  public static isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
  }

  public static isOldSafari(): boolean {
    return UtilsFun.isSafari() && Number(UtilsFun.between(navigator.userAgent, 'Version/', '.')) < 14;
  }

  // Formatter
  public static numberFormatter(num: any, decimals?: number, myStyle?: StyleFormat | string, myCurrency?: string) {
    if (num !== undefined && num !== null) {
      if (isNaN(num)) { return num}
      const myOptions: NumberFormatOptions = {};
      myOptions.style = myStyle ? myStyle : 'decimal';
      if (myOptions.style === StyleFormat.currency) {
        myOptions.currency = myCurrency ? myCurrency : CurrencyHelper.currencyValue;
        myOptions.currencyDisplay = UtilsFun.isOldSafari() ? 'symbol' : 'narrowSymbol';
      }
      if (myOptions.style === StyleFormat.percent) {
        num = num / 100
      }
      if (decimals !== undefined) {
        myOptions.minimumFractionDigits = decimals;
        myOptions.maximumFractionDigits = decimals;
      } else if (myStyle === StyleFormat.percent) {
        myOptions.minimumFractionDigits = 2;
        myOptions.maximumFractionDigits = 2;
      }
      return new Intl.NumberFormat(FormatInfo.myLocale, (Object.keys(myOptions).length > 0) ? myOptions : undefined).format(num);
    }
    return '';
  }

  public static getDate(date: number): string {
    if (!date) {
      return '-'
    }
    return new Date(date).toLocaleDateString();
  }

  public static pad(val: number): string {
    return (val < 10) ? '0' + val : val + '';
  }

  public static getOnlyDate(timestamp: number): string {
    const dat = new Date(timestamp);
    return UtilsFun.pad(dat.getDate()) + '/' + UtilsFun.pad(dat.getMonth() + 1) + '/' + dat.getFullYear();
  }

  public static getOnlyHour(timestamp: number): string {
    const dat = new Date(timestamp);
    return UtilsFun.pad(dat.getHours()) + ':' + UtilsFun.pad(dat.getMinutes());
  }

  // mostra percentuale di allocazione del budget
  public static showBudgetPerc(myFlight: Flight, preno: Preno): string {
    let budgetPerc = '';
    if (myFlight.quantity && preno && preno.budget) {
      if (myFlight.booking_event === EventsIds.TOTAL_COST) {
        budgetPerc = (~~((myFlight.quantity / preno.budget) * 100)) + '';
      } else if (myFlight.booking_event === EventsIds.IMPRESSIONS && preno.currency_value) {
        budgetPerc = (~~((myFlight.quantity / UtilsFun.calculateEstimated(preno.budget, preno.currency_value)) * 100)) + '';
      }
    }
    return budgetPerc;
  }

  public static getIntForProgress(a: string): number {
    let num = parseInt(a, 10);
    if (num > 100) {
      num = 100;
    }
    if (num && !isNaN(num)) {
      return num;
    }
    return 0;
  }

  public static setLowercase(text: string): string {
    return text ? text.toLocaleLowerCase() : '';
  }

  public static getCode(id: number, order_value?: boolean, audienceGroup_id?: number): string {
    const order = order_value ? '&order_value=${ORDER_VALUE}' : '';
    const seg = audienceGroup_id ? '&segment_group=' + audienceGroup_id : '&segment_id=' + id;

    let res = '<!-- Install one of the two scripts on the page. You can choose the one you prefer according to your needs. Only ONE must be present. -->\n\n\n' +
      '<!-- Option 1 --> \n<script async src="https://onetag-sys.com/audience-router/?tag=script' + seg + order + '" referrerpolicy="no-referrer-when-downgrade"></script>';

    // New code
    res += '\n\n<!-- Option 2: to be used for Google Tag Manager --> \n<script> \n' +
    '\t var s = document.createElement("script");\n' +
    '\t s.async=true; \n' +
    '\t s.referrerPolicy="no-referrer-when-downgrade";\n' +
    '\t s.src="https://onetag-sys.com/audience-router/?tag=script' + seg + order + '"\n' +
    '\t document.body.appendChild(s);\n' +
    '</script>';
    return res;
  }

  public static getCodeNoTagManager(id: number, order_value?: boolean, audienceGroup_id?: number): string {
    const order = order_value ? '&order_value=${ORDER_VALUE}' : '';
    const seg = audienceGroup_id ? '&segment_group=' + audienceGroup_id : '&segment_id=' + id;
    return '<script async src="https://onetag-sys.com/audience-router/?tag=script' + seg + order + '" referrerpolicy="no-referrer-when-downgrade"></script>';
  }


  public static getCodeForTagManager(id: number, order_value?: boolean, audienceGroup_id?: number): string {
    const order = order_value ? '&order_value=${ORDER_VALUE}' : '';
    const seg = audienceGroup_id ? '&segment_group=' + audienceGroup_id : '&segment_id=' + id;
    return '<script> \n' +
      'var s = document.createElement("script");\n' +
      's.async=true; \n' +
      's.referrerPolicy="no-referrer-when-downgrade";\n' +
      's.src="https://onetag-sys.com/audience-router/?tag=script' + seg + order + '"\n' +
      'document.body.appendChild(s);\n' +
      '</script>';
  }


  public static getCodeForTagManagerNoCookie(id: number, isAccountID?: boolean): string {
    return '<script> \n' +
      'var s = document.createElement("script");\n' +
      's.async=true; \n' +
      's.referrerPolicy="no-referrer-when-downgrade";\n' +
      's.src="https://onetag-sys.com/sync/a,' + id + '/2/?no_cookie=1' + (isAccountID ? '&account_id=ACCOUNTID"' : '"') + '\n' +
      'document.body.appendChild(s);\n' +
      '</script>';
  }

  public static getIframeCode(id: number, order_value?: boolean, audienceGroup_id?: number): string {
    const order = order_value ? '&order_value=${ORDER_VALUE}' : '';
    const seg = audienceGroup_id ? '&segment_group=' + audienceGroup_id : '&segment_id=' + id;
    return '<iframe src="https://onetag-sys.com/audience-router/?tag=iframe' + seg + order + '" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  }

  public static getNoCookieCode(id: number, isAccountID?: boolean): string {
    return '<script async src="https://onetag-sys.com/sync/a,' + id + '/2/?no_cookie=1' + (isAccountID ? '&account_id=ACCOUNTID"' : '"') + ' referrerpolicy="no-referrer-when-downgrade"></script>';
  }

  public static getMainTag(user: UserData, addingStr?: string, isCompact?: boolean) {
    return '\r\n' +
      UtilsFun.mainBody(user.encryptedId) + (addingStr ? addingStr : '') + '</script>';
  }

  private static mainBody(pubId: string) {
    return '<script>' + '\r\n' +
      '    (function(o,n,e,t,a,g){' + '\r\n' +
      '        if (o.onetag) return; g = o.onetag = function(){ g.cmd.push(arguments) };' + '\r\n' +
      '        a = n.createElement(e); a.async = true; g.cmd = [];' + '\r\n' +
      '        a.src = "https://onetag-sys.com/main.js"; n.head.appendChild(a);' + '\r\n' +
      '    }(window, document, "script"));' + '\r\n' +
      '    onetag("init", { pubId: "' + pubId + '" });' + '\r\n'
  }

  public static calculateEstimated(budget: number, cpm: number): number {
    return Math.round((((budget / cpm ) * 1000 * 1.03) * 10 ) / 10);
  }

  static generateRandomColor(seed: number) {
    if (!UtilsFun.isVoid(seed)) {
      const num = Math.abs(Math.sin(seed) * 800).toFixed(0);
      return '#' + num;
    }
    return '#1e8de2';
  }

  public static limitInput(event: number): number {
    if (event && event >= 999999999999) {
      event = 999999999999;
    }
    if (event) { // Impedisce numeri con la virgola
      event = Math.round(event);
    }
    return event;
  }

  static isVoid(elem: any): boolean {
    return elem === undefined || elem === null || elem.length === 0;
  }

  static sortSelectItem(sortMe: SelectItem[]) {
    sortMe.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  static between(a, b, c) {
    const t = a.split(b);
    if (t[1]) {
      return t[1].split(c)[0];
    }
    return undefined;
  }

  static getTimeChoice(): SelectItem[] {
    const items: SelectItem[] = [];
    let num;
    for (num = 0; num <= 23; num++) {
      items.push({label: num + ':00', value: num + ':00'});
    }
    return items;
  }

  public static divideArray<T>(array: T[], chunkSize: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  public static getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  /**
   * Selezione ricorsiva dei componenti tree
   * @param {TreeNode} node: Nodo generico dell'albero
   * @param {SelectItem[]} inventory: Valori presenti che devono popolare l'albero
   * @param {TreeNode[]} selectedTreeElements: Array risultante di elementi selezionati
   * @returns {boolean}
   */
  public static selectRecursive(node: TreeNode, inventory: SelectItem[], selectedTreeElements: TreeNode[]) {
    if (node.children) {
      let count = node.children.length;
      node.children.forEach( childNode => {
        const hasChild = UtilsFun.selectRecursive(childNode, inventory, selectedTreeElements);
        if (childNode.partialSelected !== undefined) {
          node.partialSelected = childNode.partialSelected;
          node.expanded = childNode.partialSelected;
        }
        if (hasChild === true) {
          count --;
          node.expanded = true;
          node.partialSelected = hasChild;
          if (count === 0) {
            // Close if full
            // node.partialSelected = false;
            // node.expanded = false;
          }
        }
      });
    }
    if (inventory && inventory.some(elem => (elem.value !== undefined) && (elem.value === node.data))) {
      selectedTreeElements.push(node);
      return true;
    }
    return false;
  }

  public static splitMultipleStringOnlyNewline(myString: string): string[] {
    return myString.split(',\n').join('\n').split(' \n').join('\n').split('\n')
      .filter(elem => !UtilsFun.isVoid(elem));
  }

  public static splitMultipleString(myString: string): string[] {
    return myString.split(', ').join('\n').split(',\n').join('\n').split(',')
      .join('\n').split(' ').join('\n').split(' \n').join('\n').split('\n')
      .filter(elem => !UtilsFun.isVoid(elem));
  }

  /* Prende in input la data nel formato MMDDYYYY e il carattere di split ('/','-',':' ecc)
   * e restituisce la data nel formato DDMMYYY nel tipo Date
   */
  public static getDDMMYYYYDateFormat(date: string, split: string): Date {
    const parts = date.split(split);
    return new Date(parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10));
  }

  static validateEmail(email: string) {
    return Constants.emailRegex.test(email);
  }

  static cleanObjProprerty(obj) {
    if (!obj) {return; }
    for (const propName in obj) {
      if (obj.hasOwnProperty(propName) && obj[propName] === null || obj[propName] === undefined || obj[propName].length === 0) {
        delete obj[propName];
      }
    }
  }

  static findEntityType(id: string|number): {id: number, name: string, color: string} {
    return Constants.ENTITIES.find(elem => elem.id + '' === id + '');
  }

  static getLabelFromChoice(value: any, choice: SelectItem[]): string {
    const elemen: SelectItem = UtilsFun.getElementFromChoice(value, choice);
    if (elemen) {
      return elemen.label;
    }
    return '';
  }

  static getElementFromChoice<T extends SelectItem >(value: any, choice: T[]): T | undefined {
    let elemen: T;
    if (value !== undefined && choice && choice.length > 0) {
      elemen = choice.find(elem => elem.value === value);
    }
    return elemen;
  }

  static monthYearFrom2018(all?: string, startDate?: string): SelectItem[] {
    const timeDay = 2628000000;
    const beginDay = new Date(startDate).getTime();
    const todayDate = new Date().getTime() - timeDay; // Last month
    const datesChoice = [];
    for (let ms = beginDay , last = todayDate; ms < last; ms = new Date(ms).setMonth(new Date(ms).getMonth() + 1)) {
      let labl: string = new Date(ms).getMonth() + 1 + '/' + new Date(ms).getFullYear();
      if (new Date(ms).getMonth() < 9) { labl = '0' + labl; } // Mette lo 0 prima dei mesi rappresentati da 1 cifra
      datesChoice.push({value: labl, label: labl});
    }
    if (all) {
      datesChoice.push({value: undefined, label: all});
    }
    return datesChoice.reverse();
  }

  static put0toTime(number: string) {
    if (parseInt(number, 10) <= 9) {
      return '0' + number;
    }
    return number;
  }

  static hideChild(className: string) {
    const divs: HTMLCollectionOf<Element> = document.getElementsByClassName(className);
    if (divs) {
      for (let x = 0; x < divs.length; x++) {
        if ((divs.item(x) as HTMLElement).style.visibility !== 'hidden') {
          (divs.item(x) as HTMLElement).style.visibility = 'hidden';
          (divs.item(x) as HTMLElement).style.height = '0px';
        } else {
          (divs.item(x) as HTMLElement).style.visibility = 'visible';
          (divs.item(x) as HTMLElement).style.height = 'auto';
        }
      }
    }
  }

// TODO: crea il campo key per il p-tree per farlo funzionare. Modificare le API per includere già questo campo
  public static mapTreeNodes(nodes: TreeNode[]): TreeNode[] {
    return nodes.map(node => ({
      ...node,
      key: node.data, // Imposta key uguale a data
      children: node.children ? this.mapTreeNodes(node.children) : undefined
    }));
  }

  public static generateRandomString(length: number): string {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( let i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  /***************************************************** MAIN TAG **************************************************************/

  public static getAutoTag(pubId: string): string {
    return '  onetag("autoPlacements", {pubId: "' + pubId + '"});\r\n'
  }

}
