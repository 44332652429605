import {Injectable} from '@angular/core';
import {GlobalSettings} from 'GlobalSettings';
import {SelectItem, TreeNode} from 'primeng/api';
import {Campaign} from '../__helpers/entities/Campaign';
import {Preno} from '../__helpers/entities/Preno';
import {AudienceBuilder} from '../__helpers/entities/AudienceBuilder';
import {CampaignTag} from '../__helpers/entities/CampaignTag';
import {CountryData} from '../__helpers/entities/CountryData';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/catch';
import {InfoService} from './info.service';
import {CountryLatLng} from '../__helpers/entities/CountryLatLng';
import {LatLng} from '../__helpers/entities/LatLng';
import {Language} from '../__helpers/entities/language.class';
import {Format} from '../__helpers/entities/format.class';
import {Sizes} from '../__helpers/entities/Sizes';
import {map} from 'rxjs/operators';

@Injectable()
export class CampaignService {

  static templates: SelectItem[];
  static sizes: SelectItem[];
  static languages: SelectItem[];
  static countryData: CountryData;

  private readonly createNewCampaignURL = GlobalSettings.APIurl + '/api/createNewCampaign';
  private readonly getSizesNewCampaignURL = GlobalSettings.APIV2url + '/v2/sizes';
  private readonly getKPIMetricsURL = GlobalSettings.APIurl + '/api/getKPIMetrics';
  private readonly getIntegrationsURL = GlobalSettings.APIurl + '/api/getIntegrations';
  private readonly getCampaignTypeURL = GlobalSettings.APIurl + '/api/getCampaignType';
  private readonly getNetworksURL = GlobalSettings.APIurl + '/api/getNetworks';
  private readonly updateClientValueURL = GlobalSettings.APIurl + '/api/updateClientValue';
  private readonly confirmConsumptiveURL = GlobalSettings.APIurl + '/api/confirmConsumptive';
  private readonly getSocialCategoriesURL = GlobalSettings.APIurl + '/api/getSocialCategories';
  private readonly getDemoCategoriesURL = GlobalSettings.APIurl + '/api/getDemoCategories';
  private readonly getBidFeedbackURL = GlobalSettings.APIurl + '/api/getBidFeedback';
  private readonly getBidPercentileURL = GlobalSettings.APIurl + '/api/getBidPercentile';
  private readonly getCoordCountriesURL = GlobalSettings.APIurl + '/api/getCoordCountries';
  private readonly saveAudienceURL = GlobalSettings.APIurl + '/api/saveAudience';
  private readonly getOldAudienceURL = GlobalSettings.APIurl + '/api/getOldAudience';
  private readonly campaignCloneURL = GlobalSettings.APIurl + '/api/campaignClone';

  // Country geolocalization
  private readonly countryLatLng = new CountryLatLng();

  kpiMetrics: SelectItem[] = [];

  constructor(private http: HttpClient, private infoService: InfoService) { }

  loadKpiMetrics() {
    this.kpiMetrics = [];
    this.getKPIMetrics().subscribe(res => {
      if (res && res.length > 0) {
        this.kpiMetrics = res.sort((a, b) => (a.value < b.value) ? 1 : -1);
      }
    })
  }

  loadInitCampaign() {
    this.infoService.getLanguages().subscribe((res) => {
      CampaignService.languages = res.map(p => {
        const choice: SelectItem = {
          value: p.alpha2,
          label: p.english
        }
        return choice;
      });
    });
    this.infoService.getFormats().subscribe((res) => {
      CampaignService.templates = res.filter(elem => elem.id !== 5).map(p => {
        const choice: SelectItem = {
          value: p.id,
          label: p.name
        }
        return choice;
      });  // No siteunder
    });
    this.getSizesSelectItems().subscribe((res) => {
      CampaignService.sizes = res;
    });
    this.getCoordsInfo();
    this.loadKpiMetrics();
  }

  getCoordsInfo() {
    this.getCoordCountries().subscribe(res => {
      let retVal: CountryData = {};
      if (res && res.length > 0) {
        retVal = res.find(elem => elem.value === this.infoService.user.country);
        if (retVal) {
          let coord: LatLng;
          coord = this.countryLatLng.countryMap.get(retVal.isoCode);
          retVal.latitude = coord.latitude;
          retVal.longitude = coord.longitude;
          retVal.radius = 100000;
          retVal.zoom = 6;
        }
      }
      CampaignService.countryData = retVal;
    });
  }

  getOldAudience(idAdvertiser: number): Observable<AudienceBuilder[]> {
    const params: any = {};
    if (idAdvertiser !== undefined) { params['idAdvertiser'] = idAdvertiser.toString(); }
    return this.http.get<AudienceBuilder[]>(this.getOldAudienceURL, {params: params});
  }

  saveAudience(audience: AudienceBuilder): Observable<AudienceBuilder> {
    const params: any = {};
    return this.http.post<AudienceBuilder>(this.saveAudienceURL, audience, {params: params});
  }

  // crea nuova campagna
  createNewCampaign(campaign: Campaign | CampaignTag): Observable<Campaign> {
    return this.http.post<Campaign>(this.createNewCampaignURL, campaign);
  }

  getSizesNewCampaign(): Observable<Sizes[]> {
    return this.http.get<Sizes[]>(this.getSizesNewCampaignURL);
  }

  getSizesSelectItems(): Observable<SelectItem[]> {
    return this.getSizesNewCampaign().pipe(
      map((sizes: Sizes[]) =>
        sizes.map(size => ({
          value: size.id,
          label: size.width > 0 && size.height > 0
            ? `${size.name} (${size.width} X ${size.height})`
            : size.name
        }))
      )
    );
  }

  getSizes(): Promise<SelectItem[]> {
    if (!CampaignService.sizes || CampaignService.sizes.length === 0) {
      return this.getSizesSelectItems().toPromise();
    } else {
      return Promise.resolve(CampaignService.sizes);
    }
  }

  getKPIMetrics(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getKPIMetricsURL);
  }

  getIntegrations(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getIntegrationsURL);
  }

  getCampaignType(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getCampaignTypeURL);
  }

  getNetworks(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getNetworksURL);
  }

  updateClientValue(sendElem: any): Observable<Preno> {
    return this.http.post<Preno>(this.updateClientValueURL, sendElem);
  }

  confirmConsumptive(id: number, consuntivo?: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    if (consuntivo !== undefined) { params['consuntivo'] = consuntivo.toString(); }
    return this.http.post(this.confirmConsumptiveURL, '', {params: params});
  }

  getSocialCategories(): Observable<TreeNode[]> {
    return this.http.get<{data: TreeNode[]}>(this.getSocialCategoriesURL).map(res => res.data);
  }

  getDemoCategories(): Observable <TreeNode[]> {
    return this.http.get<{data: TreeNode[]}>(this.getDemoCategoriesURL).map(res => res.data);
  }

  getBidFeedback(id: number, timeSelected: number): Observable<{cpm?: number, count?: number}[]> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    if (timeSelected !== undefined) { params['today'] = timeSelected.toString(); }
    return this.http.get<{cpm?: number, count?: number}[]>(this.getBidFeedbackURL, {params: params});
  }

  getBidPercentile(id: number, timeSelected: number): Observable<{ cpm?: number, percentile?: number }[]> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    if (timeSelected !== undefined) { params['today'] = timeSelected.toString(); }
    return this.http.get<{cpm?: number, percentile?: number}[]>(this.getBidPercentileURL, {params: params});
  }

  getCoordCountries(): Observable<CountryData[]> {
    return this.http.get<CountryData[]>(this.getCoordCountriesURL);
  }

  campaignClone(param: Campaign): Observable<{id: number}> {
    const params: any = {};
    if (param !== undefined) { params['id'] = param.toString() }
    return this.http.post<{id: number}>(this.campaignCloneURL, '', {params: params});
  }
}
